import { render } from "./goodsDetail.vue?vue&type=template&id=8988c62e&scoped=true"
import script from "./goodsDetail.vue?vue&type=script&lang=js"
export * from "./goodsDetail.vue?vue&type=script&lang=js"

import "./goodsDetail.vue?vue&type=style&index=0&id=8988c62e&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-8988c62e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8988c62e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8988c62e', script)) {
    api.reload('8988c62e', script)
  }
  
  module.hot.accept("./goodsDetail.vue?vue&type=template&id=8988c62e&scoped=true", () => {
    api.rerender('8988c62e', render)
  })

}

script.__file = "src/views/home/goodsDetail/goodsDetail.vue"

export default script