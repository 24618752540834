<template>
    <div class="goodsDetail">
        <headBack :showKef='true'>
            <template v-slot:title>
                <div class='title'>
                    服务详情
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='top'>
            <div class='title'>
              <div>
                <img :src='goodsdetail.headImg' class='detail_top'/>
              </div>

                <div class='border_btm'>
                    <div class='title_top'>
                        <div class='title_name'>{{goodsdetail.serviceName}}</div>
                        <!-- <div class='sale_num'>已售:2</div> -->
                    </div>
                    <div class='dero'>{{goodsdetail.serviceIntro}}</div>
                    <div class='money'><span class='small'>¥</span>{{goodsdetail.price}}<span class='small' v-if="goodsdetail.roleCode != '011' && goodsdetail.firsvcode != '002'">/次</span></div>
                </div>
            </div>
<!--            <div class='institution'>本服务由北京快天使医护到家护理站为您提供服务</div>-->
        </div>
        <div class='line'></div>
        <div class='content'>
            <div class='js_tit'>产品介绍</div>
            <div class="imgs" v-if="imgs.length > 0">
              <img :src="item" alt=""  v-for='(item,index) in imgs' :key='index'>
            </div>
            <div v-else>
              <div class='c_list' v-for='(item,index) in JSON.parse(goodsdetail.content)' :key='index'>
  <!--                <div class='c_title' v-if='item.title'>{{item.title}}</div>-->
                  <div class='c_cont'>
                      <div :class='{fs:!dataIndex}' v-for='(data,dataIndex) in item.content'>{{data}}</div>
                  </div>
              </div>
              <div class="youshi">
                  <span><img src="https://m.yihu365.cn/images/Services/ser_hushi.png"><p>执业护士<br>专业服务</p></span>
                  <span><img src="https://m.yihu365.cn/images/Services/ser_toubao.png"><p>医患投保<br>全程安心</p></span>
                  <span><img src="https://m.yihu365.cn/images/Services/ser_tuandui.png"><p>专业团队<br>随时售后</p></span>
              </div>
            </div>
        </div>
        <div class="list_box">
          <div class='c_list' v-for='(item,index) in JSON.parse(goodsdetail.content1)' :key='index'>
            <div class='c_title' v-if='item.title'>{{item.title}}</div>
            <div class='c_cont'>
              <div :class='{fs:!dataIndex}' v-for='(data,dataIndex) in item.content'>{{data}}</div>
            </div>
          </div>
        </div>

        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <div class='money'><span class='small'>¥</span>{{goodsdetail.price}}<span class='small' v-if="goodsdetail.roleCode != '011' && goodsdetail.firsvcode != '002'">/次</span></div>
                <van-button type="success" color='#00C291' class='submit_btn' round @click='gotoSorderPage'>立即预约</van-button>
            </div>
        </div>
    </div>



</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import loginApi from '@axios/login'
import homeApi from '@axios/home'


export default {
  name: 'goodsDetail',
  components: {},
  setup () {

    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let serviceType = route.query.serviceType ? route.query.serviceType : '';
    localStorage.setItem('detailUrl',router.currentRoute.value.name)
    localStorage.setItem('detailQuery',JSON.stringify(router.currentRoute.value.query))

    const Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : '' //护士任务参数 手机号
    const batchNumber = route.query.batchNumber ?  route.query.batchNumber : '' //护士任务参数

      const scdSvCode = ref ('');
    let goodsdetail = ref({
        content : '[]',
        content1 : '[]',
    });
     const imgs = ref([
        // 'https://file.yihu365.cn/detailPage/jkzijian/gaoguan_04.png'
     ])
    const getDetail = (roleCode,firsvCode,scdsvCode) => {
        let params = {
           // id : id,
            rolecode:roleCode,
            firsvcode:firsvCode,
            scdsvcode:scdsvCode
        }
        homeApi.goodsDetail(params).then((res)=>{
            goodsdetail.value = res.data;
            console.log(goodsdetail.value.content)
            scdSvCode.value = res.data.scdSvCode ? res.data.scdSvCode : '';
            if(res.data.imgs){
              imgs.value = res.data.imgs
            }
            setWxShareDate(`医护到家：${goodsdetail.value.serviceName}`,goodsdetail.value.headImg)//分享
        })
    }

    getDetail(route.query.rolecode,route.query.firsvcode,route.query.scdsvcode)


    const  gotoSorderPage = () => {
        let examCode = route.query.examCode;
        if(route.query.rolecode == '011' ){
            router.push({
                name:"bloodSorder",
                query:{
                    code:goodsdetail.value.firsvcode,
                    roleCode:goodsdetail.value.roleCode,
                    scdSvCode:scdSvCode.value,
                    price:goodsdetail.value.price,
                    Recommenduserphone:Recommenduserphone,
                    batchNumber:batchNumber
                }
            })

        }
        if(route.query.rolecode == '099' && route.query.firsvcode == '004'){
          let formData = new FormData();
          formData.append('data',JSON.stringify({
            function : 'subscribe',
            token : store.state.token,
            userid : store.state.uid,
            userName : store.state.uid,
            roleType : route.query.rolecode,//目标用户ID
            service : route.query.firsvcode,
            orderType : '1',
            price : goodsdetail.value.price,
            sid : store.state.sid,
            _from : 'h5',
            version : store.state.version,
            newversion : store.state.newversion,
            source : 'h5_users_002',
            jumpMark :  store.state.jumpMark,
          }))

          formData.append('encryption',false)
          loginApi.loginAction(formData).then((res)=>{

             console.log(res);
            router.push({
              name : 'pay',
              query : {
                fee :res.data.price ,
                orderId :res.data.orderId,
                roleCode : route.query.rolecode,
                serviceCode : route.query.firsvcode,
              }
            })

          })

        }else{
            router.push({
                name:"sorder",
                query:{
                    code:goodsdetail.value.firsvcode,
                    roleCode:goodsdetail.value.roleCode,
                    scdSvCode:scdSvCode.value,
                    examCode :examCode ? examCode : '',
                    Recommenduserphone:Recommenduserphone,
                    batchNumber:batchNumber,
                    serviceType:serviceType
                }
            })
        }

    }


    // 护士推广绑定用户部分
    const setBindNurse =()=> {
      const params ={
        userId:store.state.uid,
        recommenduserphone:Recommenduserphone
      }
      homeApi.setBindUserToNurse(params).then((res) => {


        if(res.code == '0000'){
          Dialog.alert({
            title:'温馨提示',
            message:'恭喜您，已跟护士绑定成功',
            theme:'round-button',
            confirmButtonColor:'rgb(0, 194, 145)'
          })
        }else{
          // Dialog.alert({
          //   title:'温馨提示',
          //   message:'很抱歉，您已绑定过护士了',
          //   theme:'round-button',
          //   confirmButtonColor:'rgb(0, 194, 145)'
          // })
        }

      })
    }


    if(Recommenduserphone){

      if(store.state.token){//已经登录
        setBindNurse();
      }else{
        Dialog.alert({
          title:'温馨提示',
          message:'尊敬的用户您好，为方便给您提供更好的服务，您可以跟护士做绑定关系，点击立即绑定即可。',
          theme:'round-button',
          confirmButtonText:'立即绑定',
          confirmButtonColor:'rgb(0, 194, 145)'
        }).then(()=> {


          router.push({
            name : 'login',
            query : {
              Recommenduserphone:Recommenduserphone,
              batchNumber:batchNumber
            }
          })
        })
      }

    }


    return {
        imgs,
        scdSvCode,
        goodsdetail,
        router,
        store,
        gotoSorderPage,

    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.list_box{
  padding-bottom: 180px;
}
    .c_list{
        clear:both;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

        padding:25px 30px 0px;

        .fs{
            margin-bottom: 10px;
        }
        .c_title{
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
        .c_cont{
            margin-top:10px;
        }
    }
    .content{
        //padding:20px 30px;


       .imgs{
         width: 100%;
         img{
           display: block;
           width: 100%;
         }
       }
        .js_tit{
           padding:20px 0 20px 30px;
           font-size: 32px;
           color:#333;
           font-weight: 500;
        }
        .youshi{
            margin-top:20px;
            span{
                display: block;
                width: 33%;
                text-align: center;
                float: left;
                img{
                    width: 50%;
                    height: auto;
                    margin: 0 auto;
                }
                p{
                    font-size: 24px;
                    margin:0;
                    margin-top:10px;
                }
            }
        }
    }
    .top{
        width: 100%;
        img{
            width: 170px;
            height:170px;
            border-radius: 8px;
            margin-right: 25px;
        }
    }
    .institution{
        padding:20px 0;
        padding-left:35px;
        padding-right:25px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .top .title{
        padding-top:25px;
        padding-left:35px;
        padding-right:25px;
        display: flex;
        .border_btm{
            padding-bottom:20px;
            width: 72%;
            box-sizing: border-box;
            padding-right: 10px;
        }
        .dero{
             width: 100%;
            overflow:hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 26px;
            font-weight: 400;
            color: #909399;
            margin-top:10px;
        }

    }
    .title_top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 14px;
        .title_name{
            font-size: 34px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
        .sale_num{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7F7E7E;
        }
    }
    .money{
      margin-right:40px;
      color:#FF6161;
      font-size: 45px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      .small{
        font-size:28px;
        margin:0 5px;
      }
    }
    .btn_btm_cont{
        width: 100%;
        position: fixed;
        bottom: 0;
        left:0;
        padding:20px 0;
        // display: flex;
        // justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top:1px solid #f5f5f5;
        .btn_inner{
            padding:0 30px;
            display: flex;
            justify-content:space-between;
            align-items: center;
        }
        .order_btn{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding:0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left:20px;
        }


        .submit_btn{
            width: 200px;
            height: 80px;
            line-height: 80px;
            font-size: 14px!important;
            white-space: nowrap;
        }
    }


</style>
